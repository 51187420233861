import React from 'react'
import PaperContainer from './PaperContainer'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

export default function Store({name, onSelect, selected, id, onDisabledClick}) {

  const onClick = (id) => {
    onSelect(id)
  }

  return (
    <div className={selected ? "storeButton selected" : "storeButton deselected"}>
      { selected &&
      <CheckCircleIcon style={{position: 'absolute', top: -15, right: -15, color: 'green', fontSize: 30}}/>
      } 
      { !selected &&
      <CheckCircleIcon style={{position: 'absolute', top: -15, right: -15, color: 'grey', fontSize: 30}}/>
      }
      <a onClick={() => onClick(id)}>
      <PaperContainer>
        <div style={{position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <span className="storeButtonName">{name}</span>
        </div>
      </PaperContainer>
      </a>
    </div>
  )
}
