import React, {useState, useEffect} from 'react';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import { Grid } from '@material-ui/core';

import CircularProgress from '@material-ui/core/CircularProgress';
import Product from '../../components/ProductItem';

import Logging from '../../Logging';

import { useTranslation } from 'react-i18next';

import * as Sentry from "@sentry/react";

/* const products = [
  {
    type: 'NVIDIA',
    title: 'NVIDIA',
    products: [
      {
        id: 'RTX3060',
        name: 'RTX 3060'
      },
      {
        id: 'RTX3060TI',
        name: 'RTX 3060 Ti'
      },
      {
        id: 'RTX3070',
        name: 'RTX 3070'
      },
      {
        id: 'RTX3070TI',
        name: 'RTX 3070 Ti'
      },
      {
        id: 'RTX3080',
        name: 'RTX 3080'
      },
      {
        id: 'RTX3080TI',
        name: 'RTX 3080 Ti'
      },
      {
        id: 'RTX3090',
        name: 'RTX 3090'
      },
    ]
  },
  {
    type: 'AMD',
    title: 'AMD',
    products: [
      {
        id: 'RX5700',
        name: 'RX 5700'
      },
      {
        id: 'RX5700XT',
        name: 'RX 5700 XT'
      },
      {
        id: 'RX6600XT',
        name: 'RX 6600 XT'
      },
      {
        id: 'RX6700',
        name: 'RX 6700'
      },
      {
        id: 'RX6700XT',
        name: 'RX 6700 XT'
      },
      {
        id: 'RX6800',
        name: 'RX 6800'
      },
      {
        id: 'RX6800XT',
        name: 'RX 6800 XT'
      },
      {
        id: 'RX6900',
        name: 'RX 6900'
      },
      {
        id: 'RX6900XT',
        name: 'RX 6900 XT'
      },
    ]
  },
  {
    type: 'OTHER',
    title: 'Other',
    products: [
      {
        id: 'PS5',
        name: 'Sony PlayStation 5'
      }
    ]
  }
] */

export default function MyAccount() {

  const { t } = useTranslation();

  const [loading, setLoading] = useState(true)
  const [userSettings, setUserSettings] = useState({
    active: false,
    pushoverKey: '',
    stores: {},
    products: {}
  })
  const [modalVisible, setModalVisible] = useState(false)
  const [products, setProducts] = useState({})

  useEffect(() => {
    getProducts();
  }, [])

  const updateSettings = async (id) => {
    let settings = {...userSettings, products: {...userSettings.products, [id]: !userSettings.products[id]}}
    setUserSettings({...userSettings, products: {...userSettings.products, [id]: !userSettings.products[id]}})
    saveSettings(settings);
    Logging(`Updated product ${id} to ${!userSettings.products[id]}`, firebase.auth().currentUser)
  }

  const getProducts = async () => {
    try {
      let products = await firebase.firestore().collection('allProducts').get();
      let res = {};
      products.forEach(product => {
        let data = {
          id: product.id,
          ...product.data()
        };
        console.log(data)
        if (!!res[data.productType]) {
          res[data.productType].products.push(data)
        } else {
          res[data.productType] = {}
          res[data.productType].products = [];
          res[data.productType].productType = data.productType; 
          res[data.productType].productTypeTitle = data.productTypeTitle;
          res[data.productType].products.push(data)
        }
      })
      console.log(res)
      setProducts(res)
      await getUserProducts();
      setLoading(false)
    } catch (err){
      window.alert('Could not fetch products')
      console.log(err)
      Sentry.captureException(err, {
        user: firebase.auth().currentUser
      })
    }
  }

  const getUserProducts = async () => {
    let user = firebase.auth().currentUser;
    if (user) {
      let response = await firebase.firestore().collection('userSettings').doc(user.uid).get();
      let data = response.data();
      if (data !== undefined) {
        setUserSettings({...userSettings, ...data})
      }
      
      setLoading(false)
    } else {
      setLoading(false)
      window.alert('Could not fetch user data')
      setLoading(false)
      Sentry.captureException('Could not fetch user data', {
        user: firebase.auth().currentUser
      })
    }
  }

  const saveSettings = async (settings) => {
    try {
      let user = firebase.auth().currentUser;
      await firebase.firestore().collection('userSettings').doc(user.uid).set(settings);
    } catch(err) {
      window.alert(err.message)
      Sentry.captureException(err)
    }
  }

  if (loading) {
    return (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <CircularProgress color={'secondary'}/>
      </div>
    )
  }

  return (
    <div className={modalVisible ? "storeModal.open" : ""}>
      <h1>{t('menu-products')}</h1>
      <p>{t('products-sub1')}</p>
      {userSettings && 
      <Grid container spacing={4}>
        { Object.keys(products).map((type, i) => (
          <Grid key={i} item xs={12} sm={12} md={12}>
            <h3>{products[type].productTypeTitle}</h3>
            <Grid container spacing={4}>
            {
              products[type].products.map((product, j) => (
                <Grid key={j} item xs={6} sm={4} md={4}>
                  <Product name={product.name} id={product.id} onDisabledClick={() => setModalVisible(true)} selected={Object(userSettings).hasOwnProperty('products') ? userSettings.products[product.id] : false } onSelect={(id) => updateSettings(id)}/>
                </Grid>
              ))
            }
            </Grid>
          </Grid>
        )).reverse()

        }
      </Grid>
      
      }
    </div>
    
  )
}