import React, { useState, useEffect } from 'react';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

import CircularProgress from '@material-ui/core/CircularProgress';
import LoadingBackdrop from '../../components/LoadingBackdrop';
import PPButton from '../../components/PPButton';

import { Grid, TextField, Select, MenuItem, InputLabel } from '@material-ui/core';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';

import PaperContainer from '../../components/PaperContainer';

import { io } from "socket.io-client";

const moment = require('moment');

export default function MyAccount() {

  const [loading, setLoading] = useState(true)
  const [loadingOverlayActive, setLoadingOverlayActive] = useState(false)
  const [currentUser, setCurrentUser] = useState(null)
  const [notificationStats, setNotificationStats] = useState([])
  const [storeUserStats, setStoreUserStats] = useState({})
  const [productUserStats, setProductUserStats] = useState({})
  const [userStats, setUserStats] = useState({})
  const [currentStoreFetched, setCurrentStoreFetched] = useState('')
  const [currentStoreQty, setCurrentStoreQty] = useState({})

  /* useEffect(() => {

    const socket = io('https://io.productpinger.com', {secure:true});

    socket.on('connect', () => {
      console.log('Connected')
      socket.on('fetchStatus', (data) => {
        setCurrentStoreFetched(data)
      })
      socket.on('storesResult', (data) => {
        let currentData = currentStoreQty;
        currentData[data.store] = data.products
        setCurrentStoreQty(currentData)
      })
    })
  }, []) */
  

  const [sendNotifications, setSendNotifications] = useState({
    title: '',
    message: ''
  })

  useEffect(() => {
    getUser();
    getStoreStats();
    getUserCount();
    test();
  }, [])

  const test = () => {
    firebase.firestore().collection('userSettings').get().then(users => {
      let str = '';
      users.forEach(user => {
        let data = user.data();
        if (data.expoPush) {
          str = str+data.expoPush + ','
        }
      })
      console.log(str)
    })
  }

  const getUser = () => {
    let user = firebase.auth().currentUser ? firebase.auth().currentUser : null;
    setCurrentUser(user)
    setLoading(false)
  }

  const getStoreStats = async () => {

    let stores = {}
    let products = {}

    await firebase.firestore().collection('userSettings').get().then(records => {
      records.forEach(record => {
        let data = record.data()
        if (data.stores) {
          Object.keys(data.stores).forEach(store => {
            if (!stores[store]) {
              stores[store] = {
                users: 0
              }
            } else {
              stores[store] = {
                users: data.stores[store] === true ? stores[store].users+1 : stores[store].users
              }
            }
          })
        }
        if (data.products) {
          Object.keys(data.products).forEach(product => {
            if (!products[product]) {
              products[product] = {
                users: 0
              }
            } else {
              products[product] = {
                users: data.products[product] === true ? products[product].users+1 : products[product].users
              }
            }
          })
        }
      })
    })
    setStoreUserStats(stores)
    setProductUserStats(products)
  }

  const getUserCount = async () => {
    let usersSum = {
      paid: {
        active: 0,
        total: 0
      },
      free: {
        active: 0,
        total: 0
      }
    }

    await firebase.firestore().collection('userSettings').get().then(users => {
      console.log(users.size)
      users.forEach(user => {
        let data = user.data();
        if (data.active) {
          usersSum.paid.total++;
          data.expoPush !== '' ? usersSum.paid.active = usersSum.paid.active+1 : usersSum.paid.active = usersSum.paid.active;
        } else {
          usersSum.free.total++;
          data.expoPush !== '' ? usersSum.free.active = usersSum.free.active+1 : usersSum.free.active = usersSum.free.active;
        }
      })
    })
    console.log(usersSum)
    setUserStats(usersSum)
  }

  const getBitlinkClicks = async (bitly) => {
    let newURL = bitly.replace(/(^\w+:|^)\/\//, '');
    return await new Promise((resolve, reject) => {
      const token = 'e20fe8b826b7a3a044c6390bd249340e8f1abf3a';
      const url = `https://api-ssl.bitly.com/v4/bitlinks/${newURL}/clicks/summary?unit=day&units=3`;
  
      fetch(url, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }).then(res => {
        if (res.ok) {
          return res.json();
        }
      }).then(json => {
        resolve(json)
      }).catch(err => console.log(err))
    })
  }

  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
  }

  const sendMassPush = () => {
    setLoading(true)
    let send = firebase.functions().httpsCallable('massNotification');
    send(sendNotifications).then(res => {
      console.log(res)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      console.log(err)
    })
  }

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <CircularProgress color={'secondary'} />
      </div>
    )
  }

  return (
    <Grid container spacing={4}>
      {
        currentUser.uid === 'V3Z4aDxL1nVW0m1gOFU41rWsFyK2' &&
        <>
        <Grid item xs={12}>
          <PaperContainer>
            <h3>Mass notifications</h3>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', marginBottom: 10 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField style={{ marginRight: 10 }} fullWidth id="outlined-basic" label="Title" variant="outlined" value={sendNotifications.title} InputLabelProps={{ shrink: true }} onChange={(val) => setSendNotifications({ ...sendNotifications, title: val.target.value })} />
                </Grid>
                <Grid item xs={12}>
                  <TextField style={{ marginRight: 10 }} fullWidth id="outlined-basic" label="Message" variant="outlined" value={sendNotifications.message} InputLabelProps={{ shrink: true }} onChange={(val) => setSendNotifications({ ...sendNotifications, message: val.target.value })} />
                </Grid>
                {/* <Grid item xs={12}>
                  <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                    Audience
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={sendNotifications.selection}
                    onChange={(val) => setSendNotifications({ ...sendNotifications, selection: val.target.value })}
                  >
                    <MenuItem value={'all'}>All</MenuItem>
                    <MenuItem value={'active'}>Subscribers</MenuItem>
                    <MenuItem value={'trial'}>Free</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                    Priority
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={sendNotifications.priority}
                    onChange={(val) => setSendNotifications({ ...sendNotifications, priority: val.target.value })}
                  >
                    <MenuItem value={2}>Emergency</MenuItem>
                    <MenuItem value={1}>High</MenuItem>
                    <MenuItem value={0}>Normal</MenuItem>
                    <MenuItem value={-1}>Low</MenuItem>
                    <MenuItem value={-2}>Lowest</MenuItem>
                  </Select>
                </Grid> */}
              </Grid>
            </div>
            <PPButton color={'green'} onClick={() => sendMassPush()}>Send</PPButton>
          </PaperContainer>
        </Grid>
        {/* <Grid item xs={12}>
          <PaperContainer>
            <h3>Status</h3>
            <p>Currently fetching: {currentStoreFetched}</p>
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Store</TableCell>
                    <TableCell align="right">Products</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(currentStoreQty).map((store, i) => (
                    <TableRow key={i}>
                      <TableCell align="left">{String(store).toUpperCase()}</TableCell >
                      <TableCell align="right">{currentStoreQty[store]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </PaperContainer>
        </Grid> */}
        <Grid item xs={12}>
          <PaperContainer>
            <h3>Users</h3>
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Type</TableCell>
                    <TableCell align="right">Active</TableCell>
                    <TableCell align="right">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(userStats).map((users, i) => (
                    <TableRow key={i}>
                      <TableCell align="left">{String(users).toUpperCase()}</TableCell >
                      <TableCell align="right">{userStats[users].active}</TableCell>
                      <TableCell align="right">{userStats[users].total}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </PaperContainer>
        </Grid>
        <Grid item xs={12}>
          <PaperContainer>
            <h3>Stores</h3>
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Store</TableCell>
                    <TableCell align="right">Users</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(storeUserStats).sort((a,b) => {return storeUserStats[b].users - storeUserStats[a].users}).map((store, i) => (
                    <TableRow key={i}>
                      <TableCell align="left">{store}</TableCell >
                      <TableCell align="right">{storeUserStats[store].users}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </PaperContainer>
        </Grid>
        <Grid item xs={12}>
          <PaperContainer>
            <h3>Products</h3>  
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Product</TableCell>
                    <TableCell align="right">Users</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(productUserStats).sort((a,b) => {return productUserStats[b].users - productUserStats[a].users}).map((product, i) => (
                    <TableRow key={i}>
                      <TableCell align="left">{product}</TableCell >
                      <TableCell align="right">{productUserStats[product].users}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </PaperContainer>
        </Grid>
        
        </>
      }
      <LoadingBackdrop visible={loadingOverlayActive} />
    </Grid>

  )
}
