import React, { useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import "firebase/analytics";
import 'firebaseui/dist/firebaseui.css';
import { useTranslation } from 'react-i18next';

import ReactPixel from 'react-facebook-pixel';

import PaperContainer from '../components/PaperContainer';

const config = {
  apiKey: "AIzaSyBQUgOY35HuLpHLxPE_uUyi3t62VtFhSm0",
  authDomain: "gpu-pinger.firebaseapp.com",
  projectId: "gpu-pinger",
  storageBucket: "gpu-pinger.appspot.com",
  messagingSenderId: "599142481128",
  appId: "1:599142481128:web:d68a0879d9713b7cf69d5d",
  measurementId: "G-6FCZMJ8YD2"
}

export default function LoginPage() {

  const { t } = useTranslation();

  useEffect(() => {
    
    
    ReactPixel.track('PageView', {content_name: 'LoginPage'});
    async function firebase_() {
      let ui = null
      const firebaseui = await import('firebaseui')
      if (!firebase.apps.length) {
        firebase.initializeApp(config)
        ui = new firebaseui.auth.AuthUI(firebase.auth())
      } else {
        firebase.app() // if already initialized, use that one
        ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth())
      }
      const uiConfig = {
        callbacks: {
          signInSuccessWithAuthResult: async function(authResult, redirectUrl) {
            if (authResult.additionalUserInfo.isNewUser) {
              ReactPixel.track('CompleteRegistration');
              firebase.analytics().logEvent('sign_up');
            } else {
              firebase.analytics().logEvent('login', {user: firebase.auth().currentUser.email});
            }
            return true;
          },
        },
        signInFlow: 'popup',
        signInSuccessUrl: window.location, // This URL is used to return to that page when we got success response for phone authentication.
        signInOptions: [
          {
            provider: firebase.auth.GoogleAuthProvider.GOOGLE_SIGN_IN_METHOD
          },
          {
            provider: firebase.auth.FacebookAuthProvider.FACEBOOK_SIGN_IN_METHOD
          },
          {
            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID
          },
          'apple.com'
        ],
        tosUrl: window.location
      }
      ui.start('#firebaseui-auth-container', uiConfig)
    }
    firebase_()
  }, [])

  useEffect(() => {
    firebase.analytics().setCurrentScreen(window.location.pathname)
    firebase.analytics().logEvent('screen_view')
  }, [])

  return (
    
      <div style={{maxWidth: 500}}>
        <PaperContainer>
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', textAlign: 'center'}}>
            <h1>{t('login-title')}</h1>
            <p>{t('login-sub')}</p>
            <div id="firebaseui-auth-container"></div>
            <p>{t('terms-text')} <a href="/terms">{t('terms-link')}</a> & <a href="/privacypolicy">Privacy Policy</a>.</p>
          </div>
        </PaperContainer>
      </div>
  )
}
