import React, {useEffect} from 'react';
import '../App.css';
import { Grid, Button } from '@material-ui/core'

import pushImage from '../4884169.png';
import scanImage from '../Wavy_Sci-01_Single-07.jpg';
import AppStoreImage from '../logo_appstore.png';
import GooglePlayImage from '../logo_googleplay.png';
import AppImage from '../appImage.png';

import { useTranslation } from 'react-i18next';

import ReactPixel from 'react-facebook-pixel';

import PaperContainer from '../components/PaperContainer';

import firebase from 'firebase/app';
import 'firebase/analytics';

export default function AboutPage() {

  useEffect(() => {
    ReactPixel.track('PageView', {content_name: 'AboutPage'});
    firebase.analytics().setCurrentScreen(window.location.pathname)
    firebase.analytics().logEvent('screen_view')
  }, [])

  const { t } = useTranslation();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <div style={{minHeight: 'calc(30vw)', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 50}}>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{maxWidth: 600}}>
              <h1 style={{fontSize: 'calc(3vw + 1.5em)', fontWeight: 300, textAlign: 'center'}}>{t('about-title')}</h1>
              <h3 style={{fontSize: 'calc(1vw + 1em)', fontWeight: 300, textAlign: 'center'}}>{t('about-subtitle')}</h3>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row',}}>
                <a style={{color: '#ED4264'}} target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/app/product-pinger/id1583605928">
                  <img style={{maxHeight: 'calc(12vw + 1em)'}} alt="App Store" src={AppStoreImage}/>
                </a>
                <a style={{color: '#ED4264'}} target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.productpinger.app">
                  <img style={{maxHeight: 'calc(12vw + 1em)'}} alt="Google Play" src={GooglePlayImage}/>
                </a>
              </div>
            </div>
            <img className={'frontImage'} alt="Screenshot" src={AppImage}/>
          </div>
            
          
          
        </div>
      </Grid>
      <Grid item xs={12} md={6} lg={6} style={{display: 'flex', alignItems: 'center', justifyContent: 'center',flexDirection: 'column'}}>
        <img style={{maxHeight: 400}} alt="Product Pinger" src={scanImage}/>
      </Grid>
      <Grid item xs={12} md={6} lg={6} style={{display: 'flex', alignItems: 'center', justifyContent: 'center',flexDirection: 'column'}}>
        <PaperContainer>
          <h2>{t('about-subtitle-1')}</h2>
          <p>{t('about-text-1')}</p>
          <p>{t('about-text-1-2')}</p>
        </PaperContainer>
      </Grid>
      <Grid item xs={12} md={6} lg={6} style={{display: 'flex', alignItems: 'center', justifyContent: 'center',flexDirection: 'column'}}>
        <PaperContainer>
          <h2>{t('about-subtitle-2')}</h2>
          <p>{t('about-text-2')} <a style={{color: '#ED4264'}} target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/app/product-pinger/id1583605928">App Store</a>/<a style={{color: '#ED4264'}} target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.productpinger.app">Google Play</a>.</p>
        </PaperContainer>
      </Grid>
      <Grid item xs={12} md={6} lg={6} style={{display: 'flex', alignItems: 'center', justifyContent: 'center',flexDirection: 'column'}}>
        <img style={{maxHeight: 400}} alt="Product Pinger" src={pushImage}/>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        
        <Grid container spacing={4}>
          <Grid item xs={12} md={7} lg={7}>
            <h2>{t('about-subtitle-3')}</h2>
            <p>{t('about-text-3')}</p>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <PaperContainer>
                <h3>{t('stores')}</h3>
                
                <ul>
                  <h4>{t('sweden')}</h4>
                  <li>Webhallen</li>
                  <li>Amazon</li>
                  <li>Inet</li>
                  <li>Komplett</li>
                  <li>NetOnNet</li>
                  <li>Power</li>
                  <li>ComputerSalg</li>
                  <li>Elgiganten</li>
                  <li>Dustin Home</li> 
                  <li>Proshop</li> 
                  <li>Alina</li>
                  <li>Shark Gaming</li> 
                </ul>
                <ul>
                  <h4>{t('norway')}</h4>
                  <li>Shark Gaming</li> 
                  <li>NetOnNet</li>
                </ul>
                <ul>
                  <h4>{t('denmark')}</h4>
                  <li>ComputerSalg</li>
                  <li>Shark Gaming</li> 
                  <li>Expert</li> 
                </ul>
                <ul>
                  <h4>{t('europe')}</h4>
                  <li>AMD.com</li>
                  <li>EVGA</li>
                </ul>
                <ul>
                  <h4>{t('united-states')}</h4>
                  <li>NewEgg</li>
                  <li>Amazon</li>
                  <li>Best Buy</li>
                </ul>
                <ul>
                  <h4>{t('united-kingdom')}</h4>
                  <li>Amazon</li>
                </ul>
                <ul>
                  <h4>{t('germany')}</h4>
                  <li>Amazon</li>
                </ul>
              </PaperContainer>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <PaperContainer>
              <h3>{t('products')}</h3>
              <ul>
                <h4>NVIDIA</h4>
                <li>RTX 3050</li>
                <li>RTX 3060</li>
                <li>RTX 3060 Ti</li>
                <li>RTX 3070</li>
                <li>RTX 3070 Ti</li>
                <li>RTX 3080</li>
                <li>RTX 3080 Ti</li>
                <li>RTX 3090</li>
                <li>RTX 3090 Ti</li>
              </ul>
              
              <ul>
                <h4>AMD</h4>
                <li>RX 5700</li>
                <li>RX 5700 XT</li>
                <li>RX 6600 XT</li>
                <li>RX 6700</li>
                <li>RX 6700 XT</li>
                <li>RX 6800</li>
                <li>RX 6800 XT</li>
                <li>RX 6900</li>
                <li>RX 6900 XT</li>
              </ul>

              <ul>
                <h4>Other</h4>
                <li>Sony PlayStation 5</li>
              </ul>
            </PaperContainer>
          </Grid>
          <Grid item xs={12} md={12} lg={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 20}}>
            <Button size="large" variant="contained" color="secondary" onClick={() => window.location.assign('/stats')}>{t('stats-button')}</Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <PaperContainer>
          <h2>{t('how-get-started-title')}</h2>
          <ul>
            <li>{t('started-1')} <a style={{color: '#ED4264'}} target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/app/product-pinger/id1583605928">App Store</a>/<a style={{color: '#ED4264'}} target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.productpinger.app">Google Play</a></li>
            <li>{t('started-2')}</li>
            <li>{t('started-3')}</li>
            <li>{t('started-4')}</li>
            
          </ul>
          <p>{t('started-5')}</p>
          {/* <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
            <Button size="large" variant="contained" color="secondary" onClick={() => window.location.assign('/account')}>{t('about-create-account')}</Button>
          </div> */}
        </PaperContainer>
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <PaperContainer>
          <h2>{t('pricing-title')}</h2>
          <p>{t('pricing-text')}</p>
        </PaperContainer>
      </Grid>

      <Grid item xs={12} md={6} lg={6}>
        <PaperContainer>
          <h2>{t('contact-us')}</h2>
          <p>{`info@productpinger.com`}</p>
          {/* <p>{t('contact-us-text')} <a target="_blank" rel="noopener noreferrer" href="https://adamnoren.se">Adam Norén Consulting</a></p>
          <span>VAT SE920428035901</span><br/>
          <span>Falsterbovägen 65</span><br/>
          <span>236 51 Höllviken</span><br/>
          <span>Sweden</span> */}
        </PaperContainer>
      </Grid>
    </Grid>
  )
}