import React from 'react'
import PaperContainer from './PaperContainer'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

export default function Store({name, imageUrl, onSelect, selected, disabled, trial, id, onDisabledClick}) {

  const onClick = (id) => {
    if (!disabled) {
      onSelect(id)
    } else {
      onDisabledClick()
    }
  }

  return (
    <div className={selected ? "storeButton selected" : "storeButton deselected"}>
      { ((selected) && (!disabled)) &&
      <CheckCircleIcon style={{position: 'absolute', top: -15, right: -15, color: 'green', fontSize: 30}}/>
      } 
      { disabled && 
      <CancelIcon style={{position: 'absolute', top: -15, right: -15, color: 'red', fontSize: 30}}/>
      }
      { ((selected) && (trial)) &&
      <CheckCircleIcon style={{position: 'absolute', top: -15, right: -15, color: 'green', fontSize: 30}}/>
      }
      { ((!selected) && (!disabled)) &&
      <CheckCircleIcon style={{position: 'absolute', top: -15, right: -15, color: 'grey', fontSize: 30}}/>
      }
      <a onClick={() => onClick(id)}>
      <PaperContainer>
        <div style={{position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <img src={imageUrl} alt={name}/>
          <span className="storeButtonName">{name}</span>
          {
            disabled &&
            <span className="storeButtonTiral">Requires Product Pinger+</span>
          }
        </div>
      </PaperContainer>
      </a>
    </div>
  )
}
