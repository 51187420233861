import React from 'react'

function PaperContainer(props) {
  return (
    <div className="paperContainer">
      {props.children}
    </div>
  )
}

PaperContainer.propTypes = {

}

export default PaperContainer

