import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { connectHitsPerPage, Stats } from 'react-instantsearch-dom';
import { useTranslation } from 'react-i18next';

const HitsPerPage = ({ items, currentRefinement, refine, createURL }) => {
  
  const [results, setResults] = React.useState(10);

  const { t } = useTranslation();

  const handleChange = (event) => {
    setResults(event.target.value);
    refine(event.target.value);
  };

  return (
    <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
      <div style={{display: 'flex', justifyContent: 'flex-end', flexDirection: 'column'}}>
        <Stats translations={{
          stats(nbHits, processingTimeMS, nbSortedHits, areHitsSorted) {
            return areHitsSorted && nbHits !== nbSortedHits
            ? `${nbSortedHits.toLocaleString()} relevant results sorted out of ${nbHits.toLocaleString()} found in ${processingTimeMS.toLocaleString()}ms`
            : `${nbHits.toLocaleString()} ${t('results-found-in')} ${processingTimeMS.toLocaleString()}ms`
          },
        }}/>
      </div>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="results-select">{t('results')}</InputLabel>
        <Select
          labelId="results-select"
          id="results-select"
          value={results}
          label="Results"
          size={'small'}
          onChange={handleChange}
        >
          {items.map(item => (
          <MenuItem value={item.value}>{t('show')} {item.value} {t('hits')}</MenuItem>
          ))}
        </Select>
        <FormHelperText>{t('results-per-page')}</FormHelperText>
      </FormControl>
    </div>
  );
};

const CustomHitsPerPage = connectHitsPerPage(HitsPerPage);

export default CustomHitsPerPage;