import React from 'react'

export default function PPButton({children, onClick, color, style}) {

  const onPress = () => {
    onClick()
  }

  return (
    <button onClick={onPress} className={`PPButton ${color}`} style={style}>
      <span>{children}</span>
    </button>
  )
}
