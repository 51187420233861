import React from 'react';
import AccountNav from '../components/AccountNav';
import { Grid } from '@material-ui/core';
import {
  Switch,
  Route,
} from "react-router-dom";

import PaperContainer from '../components/PaperContainer';

import MyAccountPage from './account/MyAccount';
import SubscriptionPage from './account/SubscriptionPage';
import StoresPage from './account/StoresPage';
import ProductsPage from './account/ProductsPage';
import AdminPage from './account/AdminPage';

export default function AccountPage() {

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={4} lg={4}>
        <PaperContainer>
          <AccountNav onChange={path => window.location.pathname = path} currentPage={window.location.pathname}/>
        </PaperContainer>
      </Grid>
      <Grid item xs={12} md={8} lg={8}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12} lg={12}>
            
              <Switch>
                <Route path="/account/my-account">
                  <MyAccountPage/>
                </Route>
                <Route path="/account/subscription">
                  <PaperContainer>
                    <SubscriptionPage/>
                  </PaperContainer>
                </Route> 
                <Route path="/account/stores">
                  <StoresPage/>
                </Route>
                <Route path="/account/products">
                  <ProductsPage/>
                </Route> 
                <Route path="/account/admin">
                  <AdminPage/>
                </Route> 
              </Switch>
            
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
