import React, {useEffect} from 'react';
import '../App.css';
import { Grid } from '@material-ui/core'

import ReactPixel from 'react-facebook-pixel';

import PPButton from '../components/PPButton';

import firebase from 'firebase/app';
import 'firebase/analytics';

import * as Sentry from "@sentry/react";

export default function SubscribePage() {

  useEffect(() => {
    ReactPixel.track('PageView', {content_name: 'AboutPage'});
    firebase.analytics().setCurrentScreen(window.location.pathname)
    firebase.analytics().logEvent('screen_view')
    Sentry.captureException('404', {
      user: firebase.auth().currentUser,
      page: window.location.pathname
    })
  }, [])

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={12} lg={12}>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
            <h1>404</h1>
            <p>Page not found</p>
            <PPButton color={'green'} onClick={() => window.location.pathname = '/'}>Home</PPButton>
        </div>
      </Grid>
    </Grid>
  )
}