import React, {useState, useEffect} from 'react';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import LoadingBackdrop from '../components/LoadingBackdrop';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import {Grid} from '@material-ui/core';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import PPButton from './PPButton'

import PaperContainer from './PaperContainer'

import 'moment/locale/sv'  // without this line it didn't work

import { useTranslation } from 'react-i18next';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const moment = require('moment');

/* const logos = {
    'Webhallen': {url:'https://www.studentkortet.se/wp-content/uploads/2021/09/webhallen-logo-ny.png', color: '#1a88cb'},
    'Inet': {url:'https://www.inet.se/gfx/inet_fb-1200x1200.png', color: '#e5253d'},
    'Komplett': {url:'https://www.komplett.se/img/p/400/580626.jpg', color: '#febf10'},
    'NetOnNet': {url:'https://yt3.ggpht.com/ytc/AAUvwnhycWrhwQDxfEbf4eD33ML46RvMzWpzcZE1_fUbnw=s900-c-k-c0x00ffffff-no-rj', color: '#ec4e55'},
    'Power': {url:'https://media-exp1.licdn.com/dms/image/C4D0BAQE971SrJJYd8Q/company-logo_200_200/0/1519906793762?e=2159024400&v=beta&t=w2UxQV98YRb26Z1rpticga23QhbsvgYT_yLcXqylgMo', color: '#f15c25'},
    'ComputerSalg': {url:'https://1375906288.rsc.cdn77.org/logo/CS-logo/CSdk15logo-square_300px.png', color: '#305983'},
    'AMD': {url:'https://cdn4.iconfinder.com/data/icons/logos-and-brands-1/512/15_Amd_logo_logos-512.png', color: 'black'},
    'NewEgg': {url:'https://c1.neweggimages.com/WebResource/Themes/Nest/logos/logo_424x210.png', color: 'black'},
    'Elgiganten': {url:'https://brommablocks.se/wp-content/uploads/2019/09/ELGIGANTEN_240x177.png', color: '#88d000'},
    'Dustin Home': {url:'https://www.dustinhome.se/dustin-home-logo.png', color: 'black'},
    'Proshop': {url:'https://www.proshop.se/mstile-310x310.png', color: '#d21616'},
    'EVGA': {url:'https://p.kindpng.com/picc/s/466-4664277_png-and-vector-evga-transparent-png.png', color: 'black'},
    'Alina': {url:'https://www.alina.se/images/layout/alinalogo12q4.png', color: '#00a3e0'},
    'Shark Gaming': {url:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMrA_tYE-jZWN6Y2N-IL-JKXj5MG60Y60Ouw&usqp=CAU', color: '#0373fd'},
    'Expert': {url:'https://logosvector.net/wp-content/uploads/2012/12/expert-logo-vector.png', color: '#ec6608'},
    'Amazon': {url:'https://cdn77.swedroid.se/wp-content/uploads/2020/10/Amazon-logo.png', color: '#ff9900'}
  } */

export const Row = (props) => {

    const [loading, setLoading] = useState(true)
    const [clicks, setClicks] = useState([])
    const [logos, setLogos] = useState({})

    useEffect(() => {
        getLogos();
    }, [])

    const { t } = useTranslation();

    const getLogos = async () => {
      try {
        let stores = await firebase.firestore().collection('stores').get();
        let res = {};
        stores.forEach(store => {
          let data = {
            id: store.id,
            ...store.data()
          };
          if (!!res[data.name]) {
            res[data.name].url = data.logo
          } else {
            res[data.name] = {}
            res[data.name].url = data.logo
          }
        })
        console.log(res)
        console.log(props.hit.store)
        setLogos(res)
        await getStatsByShortUrl(props.hit.url);
      } catch (err){
        window.alert('Could not fetch logos')
        console.log(err)
      }
    }

    moment.locale(t('moment-lang'))

    /* const handleUrlClick = async (url) => {
        let originalUrl = await getOriginalUrl(url);
        let payload = {
          'uid' : 'qm2muO', //your user ID
          'affiliate_url' : fixedEncodeURIComponent(originalUrl),
          'clickout_url': window.location.href
        };
        redirectToAffiliateURL(payload, 3000).then((response) => {
          if (!response.ok) {
              throw new Error('Invalid request');
          } else {
              response.json().then(function(data) {
                  if ('affiliate_url' in data) {
                      //window.location.replace(decodeURIComponent(data.affiliate_url));
                      window.open(decodeURIComponent(data.affiliate_url), '_blank')
                  } else {
                      throw new Error('Could not find affiliate url in response');
                  }
              })
          }
        }).catch(function(err) {
        
            // fallback to original redirect
            //window.location.replace(payload.affiliate_url);
            window.open(decodeURIComponent(payload.affiliate_url), '_blank')
        });
    } */

    const handleUrlClick = async (url) => {
        window.open(url, '_blank')
    }

    const fixedEncodeURIComponent = (str) => {
        return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
          return '%' + c.charCodeAt(0).toString(16);
        });
    }

    function redirectToAffiliateURL(payload, timeoutMs) {
        return new Promise((resolve, reject) => {
            // Set timeout timer
            let timer = setTimeout(
                () => reject(new Error('Request timed out')),
                timeoutMs
            );
            console.log(payload)
            fetch('https://goto-offer.com/api/public/v1/clickout', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(payload)
            }).then(
                response => resolve(response),
                err => reject(err)
            ).finally(() => clearTimeout(timer));
        })
      }

    const getOriginalUrl = async (shortUrl) => {
        if (String(shortUrl).includes('link.productpinger.com')) {
            return new Promise((resolve, reject) => {
            
            let slug = shortUrl.split('link.productpinger.com/')[1];
            const url = 'https://link.productpinger.com/api/clicks/';

            fetch(url + slug).then(res => res.json())
            .then(json => {
                resolve(json.longUrl)
            })
            .catch(err => {
                resolve(shortUrl)
            })
            })
        } else {
            return shortUrl
        }
    }

    const getStatsByShortUrl = async (shortUrl) => {
      if (shortUrl.includes('productpinger.com')) {
        let urlCode = String(shortUrl).split('.com/')[1]
        return await fetch('https://link.productpinger.com/api/clicks/' + urlCode).then(res => res.json())
        .then(json => {
          setClicks(json.clicks);
          setLoading(false)
        }).catch(err => {
          setLoading(false)
        })
      } else {
        setLoading(false)
      }
      return clicks.length;
    }

    const imageValid = (storeName) => {
      if (logos !== undefined && logos[storeName] !== undefined && logos[storeName].url !== undefined) {
        return logos[storeName].url
      } else {
        return "";
      }
    }

    if (loading) {
      return <p>Loading</p>
    }

    return (
      <div style={{marginBottom: 20}} key={props.hit}>
      { firebase.auth().currentUser ?
        <PaperContainer>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <img alt={props.hit.store} src={imageValid(props.hit.store)} width={80} height={80} style={{objectFit: 'contain', marginRight: 24}}/>
            <div style={{width: '100%'}}>
              <Typography variant="body2" color="textSecondary" style={{marginTop: 10}}>
                {moment(new Date(props.hit.datetime)).calendar()}
              </Typography>
              <Typography variant="h6" component="h1">
                {props.hit.product}
              </Typography> 
              <Typography style={{fontWeight: 700, fontSize: 12}}>{props.hit.name && `${props.hit.name}`}</Typography>
              <Typography>{props.hit.store} [{props.hit.market}]</Typography>
              <Typography style={{fontWeight: 700, fontSize: 24}}>{props.hit.price} {props.hit.currency}</Typography>
              <PPButton color={'green'} onClick={() => handleUrlClick(props.hit.url)}>{t('product-link')}</PPButton>
              { firebase.auth().currentUser.uid === 'V3Z4aDxL1nVW0m1gOFU41rWsFyK2' &&
              <div style={{marginTop: 10}}>
                <Accordion>
                    <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                        <Typography>{clicks.length} clicks</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            { clicks.length > 0 ?
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                {clicks.map((item, i) => (
                                  <div style={{display: 'flex', flexDirection: 'column', backgroundColor: '#FCFCFC', marginBottom: 10}}>
                                    <span style={{fontWeight: 700}}>#{i} {moment(item.date).format('YYYY-MM-DD HH:mm')}</span>
                                    <span style={{fontSize: 10}}>User-Agent: {item.agent}</span>
                                    {
                                      item.location &&
                                      <span style={{fontSize: 10}}>Location: {item.location.city}, {item.location.country}</span>
                                    }
                                    
                                  </div>
                                ))}
                            </div>
                            :
                            <div>
                                <span>No clicks</span>
                            </div>
                            }
                        </Typography>
                    </AccordionDetails>
                </Accordion>
              </div>
                }
              
            </div>
          </div>
          
        </PaperContainer>
      : 
      <PaperContainer>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <img alt={props.hit.store} src={imageValid(props.hit.store)} width={80} height={80} style={{objectFit: 'contain', marginRight: 24}}/>
        <div style={{width: '100%'}}>
          <Typography variant="body2" color="textSecondary" style={{marginTop: 10}}>
            {moment(new Date(props.hit.datetime)).calendar()}
          </Typography>
          <Typography variant="h6" component="h1">
            {props.hit.product}
          </Typography> 
          <Typography style={{fontWeight: 700, fontSize: 12}}>{props.hit.name && `${props.hit.name}`}</Typography>
          <Typography>{props.hit.store} [{props.hit.market}]</Typography>
          <Typography style={{fontWeight: 700, fontSize: 24}}>{props.hit.price} {props.hit.currency}</Typography>
          <PPButton color={'green'} onClick={() => window.location.href = "/"}>Download app to get URL</PPButton>
          
        </div>
      </div>
      
    </PaperContainer>
      }
      </div>
    )
  }