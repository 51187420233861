import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AddIcon from '@material-ui/icons/Add';
import StoreIcon from '@material-ui/icons/Store';
import MemoryIcon from '@material-ui/icons/Memory';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

import { useTranslation } from 'react-i18next';

import firebase from "firebase/app";
import "firebase/auth";

export default function AccountNav({currentPage}) {

  const { t } = useTranslation();

  const handleListItemClick = (path) => {
    window.location.pathname = path;
  };
  return (
      <List component="nav" aria-label="main mailbox folders">
        <ListItem
          button
          selected={currentPage === '/account/my-account'}
          onClick={(event) => handleListItemClick('account/my-account')}
        >
          <ListItemIcon>
            <AccountCircleIcon/>
          </ListItemIcon>
          <ListItemText primary={t('menu-my_account')} />
        </ListItem>
        <ListItem
          button
          selected={currentPage === '/account/subscription'}
          onClick={(event) => handleListItemClick('account/subscription')}
        >
          <ListItemIcon>
            <AddIcon/>
          </ListItemIcon>
          <ListItemText primary={t('menu-subscriptions')} />
        </ListItem>
        <ListItem
          button
          selected={currentPage === '/account/stores'}
          onClick={(event) => handleListItemClick('account/stores')}
        >
          <ListItemIcon>
            <StoreIcon/>
          </ListItemIcon>
          <ListItemText primary={t('menu-stores')} />
        </ListItem>
        <ListItem
          button
          selected={currentPage === '/account/products'}
          onClick={(event) => handleListItemClick('account/products')}
        >
          <ListItemIcon>
            <MemoryIcon/>
          </ListItemIcon>
          <ListItemText primary={t('menu-products')} />
        </ListItem>
      { firebase.auth().currentUser.uid === 'V3Z4aDxL1nVW0m1gOFU41rWsFyK2' &&
        <ListItem
          button
          selected={currentPage === '/account/admin'}
          onClick={(event) => handleListItemClick('account/admin')}
        >
          <ListItemIcon>
            <VerifiedUserIcon/>
          </ListItemIcon>
          <ListItemText primary={'Admin'} />
        </ListItem>
      }
      </List>
  );
}