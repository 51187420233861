import React from 'react';
import { connectRange } from 'react-instantsearch-dom';
import {Box, Slider} from '@mui/material'

const RangeSlider = ({ min, max, currentRefinement, canRefine, refine }) => {
  const [stateMin, setStateMin] = React.useState(min);
  const [stateMax, setStateMax] = React.useState(max);

  React.useEffect(() => {
    if (canRefine) {
      setStateMin(currentRefinement.min);
      setStateMax(currentRefinement.max);
    }
  }, [currentRefinement.min, currentRefinement.max]);

  if (min === max) {
    return null;
  }

  const onChange = (e) => {
    let min = e.target.value[0]
    let max = e.target.value[1]
    if (currentRefinement.min !== min || currentRefinement.max !== max) {
      refine({ min, max });
    }
  };

  const onValuesUpdated = (e) => {
    let min = e.target.value[0]
    let max = e.target.value[1]
    setStateMin(min);
    setStateMax(max);
  };

  return (
    <Slider
      min={min}
      max={max}
      getAriaLabel={() => 'Temperature range'}
      value={[currentRefinement.min, currentRefinement.max]}
      onChange={onChange}
      valueLabelDisplay="auto"
    />
  );
};

const CustomRangeSlider = connectRange(RangeSlider);

export default CustomRangeSlider;