import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import firebase from 'firebase/app';
import 'firebase/auth';
import App from './App';
import './i18nextConf';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import ReactPixel from 'react-facebook-pixel';
//import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: "https://53a0bd5cbb1e490b8661be0681e3e3cd@o857891.ingest.sentry.io/5820477",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const config = {
  apiKey: "AIzaSyBQUgOY35HuLpHLxPE_uUyi3t62VtFhSm0",
  authDomain: "gpu-pinger.firebaseapp.com",
  projectId: "gpu-pinger",
  storageBucket: "gpu-pinger.appspot.com",
  messagingSenderId: "599142481128",
  appId: "1:599142481128:web:d68a0879d9713b7cf69d5d",
  measurementId: "G-6FCZMJ8YD2"
}

firebase.initializeApp(config);

const advancedMatching = { em: firebase.auth().currentUser ? firebase.auth().currentUser.email : null, external_id: firebase.auth().currentUser ? firebase.auth().currentUser.uid : null }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: true, // enable logs
};
ReactPixel.init('1809462205984652', advancedMatching, options);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
