import React, {useEffect} from 'react';
import '../App.css';
import { Grid } from '@material-ui/core'
import FeedTimeline from '../components/FeedTimeline';
import { useTranslation } from 'react-i18next';
import firebase from "firebase/app";
import ReactPixel from 'react-facebook-pixel';
import "firebase/analytics";
//import StatsBoxes from '../components/StatsBoxes';
//import moment from 'moment';

import * as Sentry from "@sentry/react";


export default function StatsPage() {

  useEffect(() => {
    ReactPixel.track('PageView', {content_name: 'StatsPage'});
    firebase.analytics().setCurrentScreen(window.location.pathname)
    firebase.analytics().logEvent('screen_view')
  }, [])

  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={12}>
        <h1>{t('stats')}</h1>
      </Grid>      
      <Grid item xs={12} md={12} lg={12}>
        <FeedTimeline/>
      </Grid>
    </Grid>
  )
}