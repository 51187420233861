import React, {useState, useEffect} from 'react';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import LoadingBackdrop from '../components/LoadingBackdrop';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import {Grid} from '@material-ui/core';

import PPButton from './PPButton'

import PaperContainer from './PaperContainer'

import 'moment/locale/sv'  // without this line it didn't work

import { useTranslation } from 'react-i18next';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

import * as Sentry from "@sentry/react";

import algoliasearch from 'algoliasearch';
import {
  InstantSearch,
  Hits,
  SearchBox,
  Pagination,
  Highlight,
  ClearRefinements,
  RefinementList,
  Configure,
  HitsPerPage,
  connectRange
} from 'react-instantsearch-dom';

import CustomRangeSlider from './CustomRangeSlider'
import CustomHitsPerPage from './CustomHitsPerPage';

import {Row} from './Row'

const searchClient = algoliasearch(
  '7BU6DZ7CF1',
  '223c05380c99e2cd0e1ee4c08a9dbbdc'
);

const moment = require('moment');



export default function FeedTimeline() {

  const [items, setItems] = useState([])
  const [loadingOverlayActive, setLoadingOverlayActive] = useState(true);
  const [market, setMarket] = useState('all')

  const { t } = useTranslation();

  moment.locale(t('moment-lang'))

  useEffect(() => {
    getStats();
  }, [])

  const translation = {
    showMore(expanded) {
      return expanded ? t('show-less') : t('show-more');
    },
    noResults: t('no-results'),
    submitTitle: t('submit-search-query'),
    resetTitle: t('clear-search-query'),
    placeholder: t('search'),
  }

  const fixedEncodeURIComponent = (str) => {
    return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
      return '%' + c.charCodeAt(0).toString(16);
    });
  }

  

  

  function redirectToAffiliateURL(payload, timeoutMs) {
    return new Promise((resolve, reject) => {
        // Set timeout timer
        let timer = setTimeout(
            () => reject(new Error('Request timed out')),
            timeoutMs
        );

        fetch('https://goto-offer.com/api/public/v1/clickout', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(payload)
        }).then(
            response => resolve(response),
            err => reject(err)
        ).finally(() => clearTimeout(timer));
    })
  }
  
  const filterByMarket = async (market) => {
    setMarket(market)
    if (market === 'all') {
      getStats();
    } else {
      setLoadingOverlayActive(true)
      let data = await firebase.firestore().collection('stats').where('market', '==', market).orderBy('datetime', 'desc').limit(25).get();
      let raw = [];
      data.forEach(item => {
        let data = {
          ...item.data(),
          id: item.id
        }
        raw.push(data)
      })
      setItems(raw)
      setLoadingOverlayActive(false)
    }
  }

  const deleteItem = (id) => {
    firebase.firestore().collection('stats').doc(id).delete().then((res) => {
      getStats();
    }).catch(err => {
      Sentry.captureException(err)
    })
  }

  const getStats = async () => {
    setLoadingOverlayActive(true)
    let data = await firebase.firestore().collection('stats').orderBy('datetime', 'desc').limit(25).get();
    let raw = [];
    data.forEach(item => {
      let data = {
        ...item.data(),
        id: item.id
      }
      raw.push(data)
    })
    setItems(raw)
    setLoadingOverlayActive(false)
  }

  

  if (loadingOverlayActive) {
    return (
      <LoadingBackdrop visible={loadingOverlayActive} />
    )
  }

  return (
    <Grid container spacing={5}>
        <InstantSearch indexName="stats" searchClient={searchClient}>
          <Grid item xs={12} md={12} lg={12}>
            <SearchBox showLoadingIndicator autoFocus translations={translation}/>
          </Grid>
          <Grid item xs={12} sm={5} md={4} lg={3}>
            <h2>Filter</h2>
            <ClearRefinements translations={{
              reset: t('clear'),
            }}/>
            <h4>{t('product-model')}</h4>
            <RefinementList attribute="product" showMore={true} translations={translation}/>
            <h4>{t('market')}</h4>
            <RefinementList attribute="market" showMore={true} translations={translation}/>
            <h4>{t('store')}</h4>
            <RefinementList attribute="store" showMore={true} translations={translation}/>
            <h4>{t('price')}</h4>
            <CustomRangeSlider attribute="price"/>
            
            <Configure hitsPerPage={8} />
          </Grid>
            
          <Grid item xs={12} sm={7} md={8} lg={9}>
            <Grid item xs={12}>
              <CustomHitsPerPage
                items={[
                  { value: 10, label: 'Show 10 hits' },
                  { value: 25, label: 'Show 25 hits' },
                  { value: 50, label: 'Show 50 hits' },
                  { value: 100, label: 'Show 100 hits' },
                ]}
                defaultRefinement={10}
              />
              </Grid>
              <Hits hitComponent={Row} />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Pagination/>
          </Grid>
          
        </InstantSearch>
    </Grid>
  );
}