import * as React from "react";
import './App.css';
import {
  FirebaseAuthProvider,
  FirebaseAuthConsumer,
  IfFirebaseAuthed,
  IfFirebaseUnAuthed
} from "@react-firebase/auth";
import firebase from 'firebase/app';
import 'firebase/firestore';

import LoginPage from './pages/LoginPage';
import StatsPage from './pages/StatsPage';
import AboutPage from './pages/AboutPage';
import TermsPage from './pages/Terms';
import AccountPage from './pages/AccountPage'
import NotFoundPage from './pages/404'
import ChartPage from './pages/ChartPage'
import PrivacyPolicyPage from './pages/PrivacyPolicy'

import Adtraction from './pages/Adtraction'

import { useTranslation } from 'react-i18next';

import LoadingBackdrop from './components/LoadingBackdrop';
import AnnouncementBar from './components/AnnouncementBar'

import { Container, Grid, Hidden, Select, MenuItem, Button } from '@material-ui/core';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from "react-router-dom";

import * as Sentry from "@sentry/react";

import logo from './logo.png'; // with require


const moment = require('moment');

const config = {
  apiKey: "AIzaSyBQUgOY35HuLpHLxPE_uUyi3t62VtFhSm0",
  authDomain: "gpu-pinger.firebaseapp.com",
  projectId: "gpu-pinger",
  storageBucket: "gpu-pinger.appspot.com",
  messagingSenderId: "599142481128",
  appId: "1:599142481128:web:d68a0879d9713b7cf69d5d",
  measurementId: "G-6FCZMJ8YD2"
}

const App = () => {

  const { t, i18n } = useTranslation();

  moment.locale(t('moment-lang'))

  const [lastProduct, setLastProduct] = React.useState(null);
  const [signedIn, setSignedIn] = React.useState(false);

  const [authentication, setAuthState] = React.useState({
    authenticated: false,
    initializing: true
  });

  React.useEffect(() => firebase.auth().onAuthStateChanged(user => {
    if (user) {
      setAuthState({
        authenticated: true,
        initializing: false
      });
    } else {
      setAuthState({
        authenticated: false,
        initializing: false
      });
    }
  }), [setAuthState]);

  React.useEffect(() => {
    getLastProduct();
    firebase.auth().onAuthStateChanged(() => {
      if (firebase.auth().currentUser) {
        setSignedIn(true)
        Sentry.configureScope(scope => {
          scope.setUser({ email: firebase.auth().currentUser.email, id: firebase.auth().currentUser.uid })
          scope.setTag('user_lang', i18n.language)
        });
      } else {
        setSignedIn(false)
        Sentry.configureScope(scope => {
          scope.setUser(null)
          scope.setTag('user_lang', i18n.language)
        });
      }
    })
  }, [])

  const getLastProduct = async () => {
    if (authentication.authenticated) {
      const product = await firebase.firestore().collection('stats').orderBy('datetime', 'desc').limit(1).get();
      product.forEach((item) => {
        let data = item.data();
        setLastProduct(data)
      })
    }
  }

  return (
    <Router>
      <div className="container">
        <AnnouncementBar/>
        <div className="menu">
          <Container maxWidth="lg">
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Hidden xsDown>
                <Grid container>
                  {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div class="trustpilot-widget" data-locale="en-US" data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="60a58044e0a0f900018eb0ef" data-style-height="24px" data-style-width="100%" data-theme="light">
                      <a href="https://www.trustpilot.com/review/productpinger.com" target="_blank" rel="noopener">Trustpilot</a>
                    </div>
                  </Grid> */}
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    {((lastProduct !== null) && (firebase.auth().currentUser)) && <span style={{ fontSize: 14, color: 'black' }}>{t('last-product-found')} <b>{moment(new Date(lastProduct.datetime.toDate())).fromNow()}</b></span>}
                    <div style={{ marginBottom: 5 }}>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        disableUnderline

                        value={i18n.language}
                        onChange={(val) => i18n.changeLanguage(val.target.value)}
                      >
                        <MenuItem value={'en'}>English</MenuItem>
                        <MenuItem value={'sv'}>Swedish</MenuItem>
                      </Select>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <a href="/"><img className="logo" alt="Product Pinger" src={logo} /></a>
                  </Grid>
                  <Grid item xs={6} md={6} lg={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', flexDirection: 'column' }}>

                    <div className="menu-links">
                      <div className="menu-link">
                        <Link to={`/`}>{t('menu-about')}</Link>
                      </div>
                      <div className="menu-link">
                        <Link to={`/stats`}>
                          {t('menu-stats')}
                        </Link>
                      </div>
                      {/* <div className="menu-link">
                        <Button variant='contained' color="secondary" onClick={() => window.location.pathname = '/account/my-account'}>{signedIn ? t('menu-account') : t('about-create-account-nav')}</Button>
                      </div> */}
                    </div>
                  </Grid>
                </Grid>
              </Hidden>
              <Hidden smUp>
                <Grid container>
                  {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div class="trustpilot-widget" data-locale="en-US" data-template-id="5419b6a8b0d04a076446a9ad" data-businessunit-id="60a58044e0a0f900018eb0ef" data-style-height="24px" data-style-width="100%" data-theme="light">
                      <a href="https://www.trustpilot.com/review/productpinger.com" target="_blank" rel="noopener">Trustpilot</a>
                    </div>
                  </Grid> */}
                  <div style={{ position: "absolute", top: 5, right: 5 }}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      disableUnderline
                      value={i18n.language}
                      onChange={(val) => i18n.changeLanguage(val.target.value)}
                    >
                      <MenuItem value={'en'}>English</MenuItem>
                      <MenuItem value={'sv'}>Swedish</MenuItem>
                    </Select>
                  </div>
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <a href="/"><img className="logo" alt="Product Pinger" src={logo} /></a>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    {lastProduct !== null && <a href="/stats"><span style={{ fontSize: 14, color: 'black' }}>Last product found: <b>{moment(new Date(lastProduct.datetime.toDate())).fromNow()}</b></span></a>}
                    <div className="menu-links-small">
                      <div className="menu-link-small">
                        <Link to={`/`}>{t('menu-about')}</Link>
                      </div>
                      <div className="menu-link-small">
                        <Link to={`/stats`}>
                          {t('menu-stats')}
                        </Link>
                      </div>
                      {/* <div className="menu-link-small">
                        <Button variant='contained' color="secondary" onClick={() => window.location.pathname = '/account'}>{t('menu-account')}</Button>
                      </div> */}
                    </div>
                  </Grid>
                </Grid>
              </Hidden>
            </div>
          </Container>
        </div>
        <Container maxWidth="lg">
          <div className="content-container">
            <FirebaseAuthProvider {...config} firebase={firebase}>
              <FirebaseAuthConsumer>
                {authentication.initializing ?
                <LoadingBackdrop visible={true} />
                : <>
                <IfFirebaseAuthed>
                  <Switch>
                    <Route path="/stats">
                      <StatsPage />
                    </Route>
                    <Route exact path="/">
                      <AboutPage />
                    </Route>
                    <Route path="/account">
                      <AccountPage />
                    </Route>
                    <Route path="/adtraction">
                      <Adtraction />
                    </Route>
                    <Route path="/terms">
                      <TermsPage />
                    </Route>
                    <Route path="/chart">
                      <ChartPage />
                    </Route>
                    <Route path="/privacypolicy">
                      <PrivacyPolicyPage />
                    </Route>
                    <Route component={NotFoundPage}/>
                  </Switch>
                </IfFirebaseAuthed>
                <IfFirebaseUnAuthed>
                  <Switch>
                    <Route path="/stats">
                      <StatsPage />
                    </Route>
                    <Route exact path="/">
                      <AboutPage />
                    </Route>
                    <Route path="/account">
                      <LoginPage />
                    </Route>
                    <Route path="/adtraction">
                      <Adtraction />
                    </Route>
                    <Route path="/terms">
                      <TermsPage />
                    </Route>
                    <Route path="/privacypolicy">
                      <PrivacyPolicyPage />
                    </Route>
                  </Switch>
                </IfFirebaseUnAuthed>
                </>
                }
              </FirebaseAuthConsumer>
            </FirebaseAuthProvider>
          </div>
        </Container>
        <div className="footer">
          <Container maxWidth="lg">
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <a href="/"><img className="logo" alt="Product Pinger" src={logo} /></a>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
      </div>
    </Router>
  );
};

export default App;
