import React, {useState, useEffect} from 'react';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import PPButton from '../../components/PPButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import LoadingBackdrop from '../../components/LoadingBackdrop';

import { loadStripe } from '@stripe/stripe-js';

import { useTranslation } from 'react-i18next';

import * as Sentry from "@sentry/react";

import ReactPixel from 'react-facebook-pixel';
import moment from 'moment';

export default function SubscriptionPage() {

  const { t, i18n } = useTranslation();

  const [subscriptionActive, setSubscriptionActive] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingOverlayActive, setLoadingOverlayActive] = useState(false)
  const [userData, setUserData] = useState(null)

  useEffect(() => {
    getSettings();
    checkSubscriptionStatus();
  }, [])

  const getSettings = async () => {
    try {
      let user = firebase.auth().currentUser;
      let settings = await firebase.firestore().collection('userSettings').doc(user.uid).get();
      setSubscriptionActive(settings.data().active)
      setUserData(settings.data())
      setLoading(false)
    } catch (err) {
      setLoading(false)
      Sentry.captureException(err)
    }
  }

  const manageSubscriptions = async () => {
    setLoadingOverlayActive(true)
    const functionRef = firebase
      .app()
      .functions('europe-west2')
      .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
    const { data } = await functionRef({ returnUrl: window.location.origin });
    setLoadingOverlayActive(false)
    window.location.assign(data.url);
  }

  const createSubscription = async () => {
    try {
      setLoadingOverlayActive(true)
      let currentUser = firebase.auth().currentUser;
      const docRef = await firebase.firestore()
        .collection('customers')
        .doc(currentUser.uid)
        .collection('checkout_sessions')
        .add({
          price: i18n.language === 'sv' ? 'price_1IxeZXEth007Fev38LrwtQXO' : 'price_1J53m6Eth007Fev3YglvrvgU',
          tax_rates: ['txr_1InpQ2Eth007Fev3Dw9UZp6q'],
          success_url: `https://productpinger.com/account/subscription?success=true`,
          cancel_url: `https://productpinger.com/account/subscription?success=false`,
        });
      // Wait for the CheckoutSession to get attached by the extension
      docRef.onSnapshot(async (snap) => {
        const { error, sessionId } = snap.data();
        if (error) {
          // Show an error to your customer and 
          // inspect your Cloud Function logs in the Firebase console.
          Sentry.captureException(error)
          setLoadingOverlayActive(false)
          alert(`An error occured: ${error.message}`);
        }

        if (sessionId) {
          // We have a session, let's redirect to Checkout
          // Init Stripe
          const stripe = await loadStripe('pk_live_51Ikz9qEth007Fev3c3Df8Kh98imnaQn7el5tds1HISPzig3UpaS3qUA6JqOrShSKySaxBK3aE5Td9JOcw3iotxjb00M3GFWuAg');
          setLoadingOverlayActive(false)
          firebase.analytics().logEvent('begin_checkout', {
            value: 99.00,
            currency: 'SEK'
          })
          stripe.redirectToCheckout({ sessionId });
        }
      });
    } catch (err) {
      Sentry.captureException(err)
    }
  }

  const cryptoCheckout = async() => {
    const currentUser = firebase.auth().currentUser;
    const res = await fetch('https://us-central1-gpu-pinger.cloudfunctions.net/createCharge', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        uid: currentUser.uid
      })
    });
    const charge = await res.json();

    console.log(charge);
    window.location.assign(charge.hosted_url);
  }

  const checkSubscriptionStatus = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const purchaseSuccess = urlParams.get('success')
    if (purchaseSuccess === 'true') {
      if (i18n.language === 'sv') {
        firebase.analytics().logEvent('purchase', {
          value: 99.00,
          currency: 'SEK'
        })
        ReactPixel.track('Subscribe', {currency: 'SEK', value: 99.00});
      } else {
        firebase.analytics().logEvent('purchase', {
          value: 9.99,
          currency: 'USD'
        })
        ReactPixel.track('Subscribe', {currency: 'USD', value: 9.99});
      }
    }
    if (purchaseSuccess === 'false') {
    }
  }

  if (loading) {
    return (
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <CircularProgress color={'secondary'}/>
      </div>
    )
  }

  return (
    <div>
      
      <h3>{t('subscription-title')}</h3>
      { !subscriptionActive ?
      <div>
        <p>{t('subscription-no-subscription')}</p>
        <p>{t('subscription-no-subscription-2')}</p>
        <p style={{fontWeight: 700, fontSize: 24}}>{t('subscription-price')}</p>
        <p>{t('subscription-cancel')}</p>
        <div>
          <PPButton color={'green'} style={{marginBottom: 10, display: 'block'}} onClick={() => createSubscription()}>{t('subscription-buy')}</PPButton>
          <PPButton color={'blue'} style={{display: 'block'}} onClick={() => cryptoCheckout()}>Pay with Crypto</PPButton>
        </div>
        <p style={{marginTop: 10}}>{t('subscription-terms')} <a href="/terms">{t('subscription-terms_here')}</a>.</p>
      </div>
      :
      <p>{t('subscription-congrats')}</p>
      }
      <div>
        { ((userData.coinbaseStatus) && (userData.coinbaseStatus === 'confirmed')) &&
          <p>Expires {moment(userData.coinbaseExpireDate.toDate()).format('YYYY-MM-DD HH:mm')}</p>
        }
        { ((userData.coinbaseStatus) && (userData.coinbaseStatus === 'pending')) &&
          <p>Payment pending</p>
        }
        { (((!userData.coinbaseStatus) || (!userData.coinbaseStatus === 'confirmed')) && (subscriptionActive)) &&
          <PPButton color={'blue'} onClick={() => manageSubscriptions()}>{t('subscription-manage_button')}</PPButton>
        }
      </div>
      <LoadingBackdrop visible={loadingOverlayActive} />
    </div>
    
  )
}
