import React, {useState, useEffect} from 'react'
import firebase from 'firebase/app';
import 'firebase/firestore';
import ErrorIcon from '@material-ui/icons/Error';

export default function AnnouncementBar() {

  const [data, setData] = useState(null)

  useEffect(() => {
    getAnnouncement();
  }, [])

  const getAnnouncement = () => {
    firebase.firestore().collection('siteSettings').doc('announcementBar').get()
    .then(res => setData(res.data()))
  }

  if ((data === null) || (!data.active)) {
    return (
      null
    )
  }

  return (
    <div className={data.critical === true ? "announcementBar critical" : "announcementBar"}>
      <ErrorIcon style={{marginRight: 10}}/>
      <span>{data.message}</span>
    </div>
  )
}
