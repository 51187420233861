import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import * as Sentry from "@sentry/react";

const Logger = async (event, user) => {
  try {
    await firebase.firestore().collection('logs').add({
      event,
      userEmail: user ? user.email : null,
      userUID: user ? user.uid : null,
      datetime: firebase.firestore.Timestamp.now()
    })
  } catch (err) {
    console.log(err)
    Sentry.captureException(err, {
      event: event,
      user: user
    })
  }
}

export default Logger;