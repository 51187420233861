import React, {useEffect} from 'react'

import ReactPixel from 'react-facebook-pixel';

import firebase from "firebase/app";
import "firebase/analytics";

import * as Sentry from "@sentry/react";

export default function Adtraction() {

  useEffect(() => {
    logEvent();
    ReactPixel.track('PageView', {content_name: 'AdtractionPage'});
  }, [])

  const logEvent = () => {
    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);

    const commissiondecimal = urlParams.get('commissiondecimal')

    let val = parseFloat(commissiondecimal).toFixed(2);

    firebase.analytics().logEvent('purchase', {
      value: val,
      currency: 'SEK'
    })

    Sentry.captureException('Adtraction', {
      value: val,
      currency: 'SEK'
    })
  }

  return (
    <div>
      
    </div>
  )
}
