import React, { useState, useEffect } from 'react';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

import CircularProgress from '@material-ui/core/CircularProgress';
import LoadingBackdrop from '../../components/LoadingBackdrop';
import PPButton from '../../components/PPButton';

import { Grid } from '@material-ui/core';

import PaperContainer from '../../components/PaperContainer';

import { useTranslation } from 'react-i18next';

export default function MyAccount() {

  const { t } = useTranslation();

  const [loading, setLoading] = useState(true)
  const [loadingOverlayActive, setLoadingOverlayActive] = useState(false)
  const [currentUser, setCurrentUser] = useState(null)

  useEffect(() => {
    getUser();
  }, [])

  const getUser = () => {
    let user = firebase.auth().currentUser;
    setCurrentUser(user)
    setLoading(false)
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <PaperContainer>
          <h3>{t('my_account-title')}</h3>
          {currentUser &&
            <div>
              <p>{`${t('my_account-signed_in_as')} `}<b>{`${currentUser.email}`}</b></p>
              <PPButton color={'green'} onClick={() => firebase.auth().signOut()}>{t('my_account-sign_out_button')}</PPButton>
            </div>
          }
        </PaperContainer>
      </Grid>
      <LoadingBackdrop visible={loadingOverlayActive} />
    </Grid>

  )
}
