import React, {useEffect, useState} from 'react';
import '../App.css';
import { Grid, Input, Select, Checkbox, MenuItem, ListItemText } from '@material-ui/core'
import { useTranslation } from 'react-i18next';
import firebase from "firebase/app";
import ReactPixel from 'react-facebook-pixel';
import "firebase/analytics";
import { Line } from 'react-chartjs-2';
//import StatsBoxes from '../components/StatsBoxes';
//import moment from 'moment';

const state = {
  labels: ['January', 'February', 'March',
           'April', 'May'],
  datasets: [
    {
      label: 'Rainfall',
      fill: false,
      lineTension: 0.5,
      backgroundColor: 'rgba(75,192,192,1)',
      borderColor: 'rgba(0,0,0,1)',
      borderWidth: 2,
      data: [65, 59, 80, 81, 56]
    }
  ]
}

export default function ChartPage() {

  useEffect(() => {
    ReactPixel.track('PageView', {content_name: 'ChartPage'});
    firebase.analytics().setCurrentScreen(window.location.pathname)
    firebase.analytics().logEvent('screen_view')
    getChartData();
  }, [])

  const [products, setProducts] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [loading, setLoading] = useState(true)
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: []
  })

  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setSelectedProducts(value);
  };

  const handleChange = (event) => {
    setSelectedProducts(event.target.value);
  };

  function random_rgba() {
    let n = (Math.random() * 0xfffff * 1000000).toString(16);
    return '#' + n.slice(0, 6);
  }

  const getChartData = () => {
    firebase.firestore().collection('stats').orderBy('datetime', 'asc').limitToLast(200).get()
    .then(data => {
      const allData = [];
      data.forEach(item => {
        allData.push(item.data())
      })
      return allData;
    }).then(allData => {
      let newChartData = chartData;
      newChartData.labels = [...new Set(allData.map(x => new Date(x.datetime.toDate()).toLocaleDateString()))]
      newChartData.datasets = generateData(allData, 'product', newChartData.labels)
      setChartData(newChartData)
      

      let products = allData.map((product) => {
        return product.product
      })
      
      setProducts([...new Set(products)])
    }).then(() => {
      console.log(chartData)
      setLoading(false)
    })
  }
  
  const generateData = (arr, property, labels) => {
    let products = arr.reduce((acc, cur) => {
      acc[cur[property]] = [...acc[cur[property]] || [], cur];
      return acc;
    }, {});
    
    let result = Object.keys(products).map((title, i) => {
      let data = products[title].map((item, i) => {
        let statDate = new Date(item.datetime.toDate()).toLocaleDateString()
        let datePos = labels.indexOf(statDate);
        //console.log(datePos)

        return datePos
      })

      let dataVal = () => {
        let array = []
        labels.forEach(item => {
          array.push(0)
        });
        data.forEach(item => {
          array[item]++
        })
        return array;
      }

      let color = random_rgba();

      return {
        label: title,
        data: dataVal(),
        fill: false,
        lineTension: 0.5,
        backgroundColor: color,
        borderColor: color,
        borderWidth: 2,
      }
    })

    return result;
  }

  const { t } = useTranslation();

  if (loading) {
    return (
      <p>Loading</p>
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={12}>
        <h1>{t('stats')}</h1>
        <p>{t('last-25-products-found')}</p>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={selectedProducts}
          onChange={handleChange}
          input={<Input />}
          renderValue={(selected) => selected.join(', ')}
        >
          {products.map((product, i) => (
            <MenuItem key={i} value={product}>
              <Checkbox checked={selectedProducts.indexOf(product) > -1} />
              <ListItemText primary={product} />
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} md={12} lg={12} style={{marginTop: 15}}>
        <Line
          data={chartData}
          options={{
            title:{
              display:true,
              fontSize:20
            },
            legend:{
              display:true,
              position:'right'
            }
          }}
        />
      </Grid>
    </Grid>
  )
}